<template>
  <div
    v-if="
      curatorSettings &&
      mainStore.currentSite &&
      mainStore.currentSite.settings.curator
    "
    class="bg-[#1e1e1e] md:px-16 min-height: 600px"
  >
    <div class="mx-auto max-w-[2560px] py-6 px-4">
      <div :id="curatorSettings.id" />
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'

import { useMainStore } from '@/store/index'

const curatorFeeds = {
  // International
  int: {
    id: 'curator-feed-bbike-global-layout',
    src: 'https://cdn.curator.io/published/68c47b82-d135-40c3-abce-5c436b90ea84.js',
  },
  // Argentina
  ar: {
    id: 'curator-feed-theme-bbike-carousel',
    src: 'https://cdn.curator.io/published/14d3d8ae-6ead-48cb-9e62-916e9aa37ca6.js',
  },
  // Ecuador
  ec: {
    id: 'curator-feed-bbike-ecuador-layout',
    src: 'https://cdn.curator.io/published/e165b6f1-c8d7-49dc-ac94-1b54272f235e.js',
  },
  // Costa Rica
  cr: {
    id: 'curator-feed-bbike-costa-rica-layout',
    src: 'https://cdn.curator.io/published/a17ea155-0965-4258-a18c-6f605397b128.js',
  },
  // United States
  us: {
    id: 'curator-feed-bbike-usa-layout',
    src: 'https://cdn.curator.io/published/f115407a-cb09-4474-b963-b3e507ac4d9a.js',
  },
  // Peru
  pe: {
    id: 'curator-feed-bbike-peru-layout',
    src: 'https://cdn.curator.io/published/00d4ca80-b03b-47a9-b248-4ed1e0092025.js',
  },
  // El Salvador
  sv: {
    // id: 'curator-feed-bbike-el-salvador-layout',
    // src:
    //   'https://cdn.curator.io/published/6d8bb1ec-42a5-468a-aefa-c1e39c979be7.js'
    id: 'curator-feed-theme-bbike-carousel',
    src: 'https://cdn.curator.io/published/14d3d8ae-6ead-48cb-9e62-916e9aa37ca6.js',
  },
  // Guatemala
  gt: {
    id: 'curator-feed-at-bbike-gt-layout',
    src: 'https://cdn.curator.io/published/69286e3a-d0d0-4b42-b7f0-0e865eb27f60.js',
  },
  // Panama
  pa: {
    id: 'curator-feed-bbike-panama-layout',
    src: 'https://cdn.curator.io/published/3e8b4a2c-57f0-442c-b4ac-c54df10a4e03.js',
  },
  // Republica Dominicana
  do: {
    id: 'curator-feed-benelli-bike-republica-dominicana-l',
    src: 'https://cdn.curator.io/published/4e28bd65-a6ce-4ab6-a957-4c83745b43a2.js',
  },
  // Colombia
  co: {
    id: 'curator-feed-bbike-colombia-layout',
    src: 'https://cdn.curator.io/published/c0635cb9-c1c3-4358-9517-973f88df4b74.js',
  },
  // Chile
  cl: {
    id: 'curator-feed-bbike-chile-layout',
    src: 'https://cdn.curator.io/published/243ed12e-9685-49a0-a0db-855c27ccbbbb.js',
  },
  // Uruguay
  uy: {
    id: 'curator-feed-benelli-bike-uruguay-layout',
    src: 'https://cdn.curator.io/published/7859bbdd-6c61-43f7-b716-4f12966b0862.js',
  },
  // India
  in: {
    id: 'curator-feed-benelli-bike-india-layout',
    src: 'https://cdn.curator.io/published/11d0e200-de5d-4c8c-88d2-802e38fb6672.js',
  },
  // Bolivia
  bo: {
    id: 'curator-feed-benelli-bike-bolivia-layout',
    src: 'https://cdn.curator.io/published/28fd0716-c4f7-4395-9862-ef2430806d84.js',
  },
}

const mainStore = useMainStore()

const curatorSettings = reactive(
  curatorFeeds[mainStore.country]
    ? curatorFeeds[mainStore.country]
    : curatorFeeds.default
)

const scripts = []
if (import.meta.browser && mainStore.currentSite.settings.curator) {
  scripts.push({
    // class: '_iub_cs_activate',
    // 'data-suppressedsrc': curatorFeeds[mainStore.country] ? curatorFeeds[mainStore.country].src : curatorFeeds.default.src
    // 'data-iub-purposes': 3,
    crossorigin: true,
    defer: true,
    // type: 'text/plain'
    src: curatorFeeds[mainStore.country]
      ? curatorFeeds[mainStore.country].src
      : curatorFeeds.default.src,
  })
}

useHead({
  script: scripts,
})
</script>

<style lang="postcss">
@media screen and (max-width: 768px) {
  .crt-widget-carousel {
    .crt-controls-outside {
      @apply text-center px-0 !important;

      button {
        @apply inline relative bottom-[-60px] !important;

        &.crt-panel-prev {
          @apply me-4 !important;
        }
      }
    }

    .crt-match-heights .crt-carousel-pane {
      border: 1px solid transparent !important;
    }
  }
}

.crt-feed-window {
  @apply !h-auto;
}

.crt-widget-waterfall {
  min-height: auto !important;
  padding-bottom: 0 !important;
}

.crt-panel-next svg,
.crt-panel-prev svg {
  width: 100% !important;
}

.crt-widget {
  @apply !bg-[#1e1e1e];
}
</style>
