<template>
  <div class="py-[40px] md:py-[80px] text-center bg-white">
    <div class="container">
      <h2
        v-text="$t('common.become_partner_title')"
        class="text-black font-normal md:text-[32px] lg:text-[60px] mb-[10px] text-[26px] leading-[26px] lg:leading-[60px] md:leading-[32px]"
      />
      <nuxt-link
        :to="$i18nPath('/become-partner')"
        class="font-normal text-center md:leading-[36px] leading-[22px] text-[14px] md:text-[22px] text-black"
      >
        {{ $t('common.go_to_become_partner') }}
      </nuxt-link>
    </div>

    <div
      class="lg:container lg:px-10 sm:w-full hidden sm:mt-[24px] sm:px-[31px] md:mt[30px] sm:block relative mt-[30px]"
    >
      <img src="/home-become-partner-bg.png" alt="" class="rounded-xl" />
    </div>
    <div class="block sm:hidden mt-[20px] px-[15px]">
      <img
        src="/become-partner-mobile-bg.png"
        alt=""
        class="flex justify-center w-full rounded-xl"
      />
    </div>
  </div>
</template>
<script setup></script>
